$primaryColor: #00034a !default;
$primaryTextColor: #1e293b !default;

$primaryLightColor: #eaf1f2 !default;
$primaryDarkColor: #535377 !default;
$primaryDarkerColor: #1d4ed8 !default;
$primary500: #3b82f6 !default;

$highlightBg: #f8fafc !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;

@import '../_variables';
@import '../../theme-base/_components';
@import '../_extensions';
//@import '../../extensions/_fullcalendar';
