@use 'sass:math';

.p-text-success {
  color: $successButtonBg;
}

.p-text-info {
  color: $infoButtonBg;
}

.p-text-warning {
  color: $warningButtonBg;
}

.p-text-danger {
  color: $dangerButtonBg;
}
