.p-splitbutton {
  border-radius: $borderRadius;

  &.p-button-outlined {
    > .p-button {
      background-color: transparent;
      color: $buttonBg;
      border: $outlinedButtonBorder;

      &:not(:disabled):hover {
        background: $buttonBg;
        border: $buttonBg;
        color: #ffffff;
      }

      &:not(:disabled):active {
        background: rgba($buttonBg, $textButtonActiveBgOpacity);
        color: $buttonBg;
      }
    }

    &.p-button-plain {
      > .p-button {
        color: $plainButtonTextColor;
        border-color: $plainButtonTextColor;

        &:not(:disabled):hover {
          background: $plainButtonHoverBgColor;
          color: $plainButtonTextColor;
        }

        &:not(:disabled):active {
          background: $plainButtonActiveBgColor;
          color: $plainButtonTextColor;
        }
      }
    }
  }

  &.p-button-text {
    > .p-button {
      background-color: transparent;
      color: $buttonBg;
      border-color: transparent;

      &:not(:disabled):hover {
        background: rgba($buttonBg, $textButtonHoverBgOpacity);
        color: $buttonBg;
        border-color: transparent;
      }

      &:not(:disabled):active {
        background: rgba($buttonBg, $textButtonActiveBgOpacity);
        color: $buttonBg;
        border-color: transparent;
      }
    }

    &.p-button-plain {
      > .p-button {
        color: $plainButtonTextColor;

        &:not(:disabled):hover {
          background: $plainButtonHoverBgColor;
          color: $plainButtonTextColor;
        }

        &:not(:disabled):active {
          background: $plainButtonActiveBgColor;
          color: $plainButtonTextColor;
        }
      }
    }
  }

  &.p-button-raised {
    box-shadow: $raisedButtonShadow;
  }

  &.p-button-rounded {
    border-radius: $roundedButtonBorderRadius;

    > .p-button {
      border-radius: $roundedButtonBorderRadius;
    }
  }

  &.p-button-sm {
    > .p-button {
      @include scaledFontSize($fontSize, $scaleSM);
      @include scaledPadding($buttonPadding, $scaleSM);

      .p-button-icon {
        @include scaledFontSize($primeIconFontSize, $scaleSM);
      }
    }
  }

  &.p-button-lg {
    > .p-button {
      @include scaledFontSize($fontSize, $scaleLG);
      @include scaledPadding($buttonPadding, $scaleLG);

      .p-button-icon {
        @include scaledFontSize($primeIconFontSize, $scaleLG);
      }
    }
  }
}

.p-splitbutton.p-button-secondary {
  &.p-button-outlined {
    > .p-button {
      background-color: transparent;
      color: $secondaryButtonBg;
      border: $outlinedButtonBorder;

      &:not(:disabled):hover {
        background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
        color: $secondaryButtonBg;
      }

      &:not(:disabled):active {
        background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
        color: $secondaryButtonBg;
      }
    }
  }

  &.p-button-text {
    > .p-button {
      background-color: transparent;
      color: $secondaryButtonBg;
      border-color: transparent;

      &:not(:disabled):hover {
        background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
        border-color: transparent;
        color: $secondaryButtonBg;
      }

      &:not(:disabled):active {
        background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
        border-color: transparent;
        color: $secondaryButtonBg;
      }
    }
  }
}

.p-splitbutton.p-button-info {
  &.p-button-outlined {
    > .p-button {
      background-color: transparent;
      color: $infoButtonBg;
      border: $outlinedButtonBorder;

      &:not(:disabled):hover {
        background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
        color: $infoButtonBg;
      }

      &:not(:disabled):active {
        background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
        color: $infoButtonBg;
      }
    }
  }

  &.p-button-text {
    > .p-button {
      background-color: transparent;
      color: $infoButtonBg;
      border-color: transparent;

      &:not(:disabled):hover {
        background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
        border-color: transparent;
        color: $infoButtonBg;
      }

      &:not(:disabled):active {
        background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
        border-color: transparent;
        color: $infoButtonBg;
      }
    }
  }
}

.p-splitbutton.p-button-success {
  &.p-button-outlined {
    > .p-button {
      background-color: transparent;
      color: $successButtonBg;
      border: $outlinedButtonBorder;

      &:not(:disabled):hover {
        background: rgba($successButtonBg, $textButtonHoverBgOpacity);
        color: $successButtonBg;
      }

      &:not(:disabled):active {
        background: rgba($successButtonBg, $textButtonActiveBgOpacity);
        color: $successButtonBg;
      }
    }
  }

  &.p-button-text {
    > .p-button {
      background-color: transparent;
      color: $successButtonBg;
      border-color: transparent;

      &:not(:disabled):hover {
        background: rgba($successButtonBg, $textButtonHoverBgOpacity);
        border-color: transparent;
        color: $successButtonBg;
      }

      &:not(:disabled):active {
        background: rgba($successButtonBg, $textButtonActiveBgOpacity);
        border-color: transparent;
        color: $successButtonBg;
      }
    }
  }
}

.p-splitbutton.p-button-warning {
  &.p-button-outlined {
    > .p-button {
      background-color: transparent;
      color: $warningButtonBg;
      border: $outlinedButtonBorder;

      &:not(:disabled):hover {
        background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
        color: $warningButtonBg;
      }

      &:not(:disabled):active {
        background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
        color: $warningButtonBg;
      }
    }
  }

  &.p-button-text {
    > .p-button {
      background-color: transparent;
      color: $warningButtonBg;
      border-color: transparent;

      &:not(:disabled):hover {
        background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
        border-color: transparent;
        color: $warningButtonBg;
      }

      &:not(:disabled):active {
        background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
        border-color: transparent;
        color: $warningButtonBg;
      }
    }
  }
}

.p-splitbutton.p-button-help {
  &.p-button-outlined {
    > .p-button {
      background-color: transparent;
      color: $helpButtonBg;
      border: $outlinedButtonBorder;

      &:not(:disabled):hover {
        background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
        color: $helpButtonBg;
      }

      &:not(:disabled):active {
        background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
        color: $helpButtonBg;
      }
    }
  }

  &.p-button-text {
    > .p-button {
      background-color: transparent;
      color: $helpButtonBg;
      border-color: transparent;

      &:not(:disabled):hover {
        background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
        border-color: transparent;
        color: $helpButtonBg;
      }

      &:not(:disabled):active {
        background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
        border-color: transparent;
        color: $helpButtonBg;
      }
    }
  }
}

.p-splitbutton.p-button-danger {
  &.p-button-outlined {
    > .p-button {
      background-color: transparent;
      color: $dangerButtonBg;
      border: $outlinedButtonBorder;

      &:not(:disabled):hover {
        background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
        color: $dangerButtonBg;
      }

      &:not(:disabled):active {
        background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
        color: $dangerButtonBg;
      }
    }
  }

  &.p-button-text {
    > .p-button {
      background-color: transparent;
      color: $dangerButtonBg;
      border-color: transparent;

      &:not(:disabled):hover {
        background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
        border-color: transparent;
        color: $dangerButtonBg;
      }

      &:not(:disabled):active {
        background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
        border-color: transparent;
        color: $dangerButtonBg;
      }
    }
  }
}
