/* TABLE */
.custom-page-title {
  font-size: 18px;
  font-weight: 400;
  //line-height: 27px;
  margin-bottom: 24px;
}

.p-datatable-wrapper {
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.p-datatable {
  .p-datatable-tbody > tr > td,
  .p-datatable-thead > tr > th {
    border: none;
  }
}

.p-paginator {
  background: unset;
  margin-top: 30px;

  .p-paginator-current {
    font-size: 12px;
    font-weight: 275;
    line-height: 18px;
  }
  .p-paginator-pages .p-paginator-page.p-highlight {
    background: unset;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  }
  .p-dropdown {
    border-radius: 24px;
  }
}
.p-paginator-bottom {
  border: none;
}

/* DATAVIEW */

.p-dataview {
  .p-dataview-header {
    background: transparent;
    border: none;
  }
}

/* SPLIT BUTTON */
.p-splitbutton {
  .p-splitbutton-menubutton {
    border-left: none;
  }
}
