.p-stepper {
  .p-stepper-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-x: auto;

    &.p-stepper-vertical {
      flex-direction: column;
    }
  }

  .p-stepper-header {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    padding: 0.5rem;

    &:last-of-type {
      flex: initial;
    }

    .p-stepper-action {
      border: 0 none;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      transition: box-shadow 0.2s;
      border-radius: 6px;
      background: #ffffff;
      outline-color: transparent;

      &:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #c7d2fe;
      }

      .p-stepper-number {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4b5563;
        border: 2px solid #f3f4f6;
        background: #ffffff;
        min-width: 2rem;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.143rem;
        border-radius: 50%;
        transition:
          background-color 0.2s,
          color 0.2s,
          box-shadow 0.2s;
      }

      .p-stepper-title {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        margin-left: 0.5rem;
        color: #6b7280;
        font-weight: 700;
        transition:
          background-color 0.2s,
          color 0.2s,
          box-shadow 0.2s;
      }
    }

    &.p-highlight {
      .p-stepper-action {
        cursor: default;

        .p-stepper-number {
          background: #eef2ff;
          color: #4338ca;
        }

        .p-stepper-title {
          color: #4b5563;
        }
      }
    }

    &:not(.p-disabled):focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #c7d2fe;
    }

    &:has(~ .p-highlight) {
      .p-stepper-separator {
        background-color: $primaryColor;
      }
    }
  }

  .p-stepper-separator {
    flex: 1 1 0;
    background-color: #e5e7eb;
    width: 100%;
    height: 2px;
    margin-inline-start: 1rem;
    transition: box-shadow 0.2s;
  }

  .p-stepper-panels {
    background: #ffffff;
    padding: 1.25rem;
    color: #4b5563;
  }

  &.p-stepper-vertical {
    display: flex;
    flex-direction: column;

    .p-stepper-toggleable-content {
      display: flex;
      flex: 1 1 auto;
      background: #ffffff;
      color: #4b5563;
    }

    .p-stepper-panel {
      display: flex;
      flex-direction: column;
      flex: initial;

      &.p-stepper-panel-active {
        flex: 1 1 auto;
      }

      .p-stepper-header {
        flex: initial;
      }

      .p-stepper-content {
        width: 100%;
        padding-left: 1rem;
      }

      .p-stepper-separator {
        flex: 0 0 auto;
        width: 2px;
        height: auto;
        margin-inline-start: calc(1.75rem + 2px);
      }

      &:has(~ .p-stepper-panel-active) {
        .p-stepper-separator {
          background-color: $primaryColor;
        }
      }

      &:last-of-type {
        .p-stepper-content {
          padding-left: 3rem;
        }
      }
    }
  }

  &.p-stepper-readonly {
    .p-stepper-header {
      cursor: auto;
    }
  }
}
