@media screen and (min-width: 992px) {
  .layout-container.layout-horizontal .layout-sidebar {
    height: 56px;
    position: sticky;

    > div {
      display: flex;
      // width: 1504px;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}

.layout-menu .menu-separator {
  margin: 0 10px;
  border-top: solid 1px var(--primary-color-text);
}

.topbar-account-info {
  .topbar-userinfo {
    text-align: right;
    margin-right: 10px;
  }
}

//.my-canvas {
//  .konvajs-content {
//    outline: 2px dashed var(--primary-color);
//  }
//}

.app-logo {
  color: var(--primary-color-text);
  font-size: 1.4rem;
  font-weight: 600;
}

.list-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.p-text-danger a span {
  color: #ef4444; // $dangerButtonBg;
}

.p-button {
  padding: 0.5rem 1rem;
}

.white-space-nowrap {
  white-space: nowrap;
}
.menu-items-list {
  .p-orderlist-list {
    padding: 0;
    border: none;
  }
  .p-orderlist-controls {
    display: none;
  }
  .p-orderlist-item {
    padding: 0;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0;
    position: initial;
  }

  .p-panel .p-panel-content {
    padding: 0;
  }

  .p-panel .p-panel-header {
    border-radius: 0;
    background: transparent;
  }

  .p-orderlist .p-orderlist-list {
    border: none;
    min-height: 0;
    padding: 0;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0 !important;
  }
}
.table-row-disabled {
  opacity: 0.2;
  pointer-events: none;
  cursor: not-allowed;
  position: relative;
  td:last-child::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 5px solid var(--primary-color);
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: rotation 1s linear infinite;
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(-50%, -50%);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.p-orderlist-item {
  margin-bottom: 10px;
  border-radius: 24px;
  border: 1px solid #d7dded;
}

.p-panel-header {
  border: none;
  padding: 0.9rem;
  background: transparent;
}

.p-panel-content {
  border: none;
}
.upload-background-grid {
  background:
    linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.7)),
    url('assets/images/bg-pattern.png') center center repeat;
  border: 1px solid #d7dded;
  border-radius: 24px;
  background-size: cover;
}
.p-splitbutton-defaultbutton {
  border-right: none !important;
}
