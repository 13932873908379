.layout-breadcrumb {
  ol {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 1rem;
    flex-wrap: wrap;
    color: var(--text-color);

    li {
      font-weight: 600;
    }
  }
}

.content-breadcrumb {
  margin-bottom: 2rem;
  padding: 0 0.5rem;
}
