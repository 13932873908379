@media screen and (min-width: $breakpoint) {
  .layout-container {
    &.layout-static {
      .layout-sidebar {
        transition: transform var(--transition-duration);
      }

      .layout-content-wrapper {
        margin-left: 14rem;
        transition: margin-left var(--transition-duration);
      }
    }

    &.layout-static-inactive {
      .layout-sidebar {
        transform: translateX(-100%);
      }

      .layout-content-wrapper {
        margin-left: 0;
      }
    }

    &.layout-overlay {
      .layout-content-wrapper {
        margin-left: 0;
      }

      .layout-sidebar {
        z-index: 999;
        transform: translateX(-100%);
        transition: transform var(--transition-duration);
      }

      &.layout-overlay-active {
        .layout-sidebar {
          transform: translateX(0);
        }
      }
    }

    .content-breadcrumb {
      display: none;
    }
  }
}

@media screen and (max-width: ($breakpoint - 1)) {
  .blocked-scroll {
    overflow: hidden;
  }

  .layout-container {
    .layout-content-wrapper {
      margin-left: 0;
      padding: 1rem;
    }

    .layout-sidebar {
      z-index: 999;
      transform: translateX(-100%);
      transition: transform var(--transition-duration);
      box-shadow: none;
    }

    &.layout-mobile-active {
      .layout-sidebar {
        transform: translateX(0);
      }

      .layout-mask {
        display: block;
        animation: fadein var(--transition-duration);
      }
    }

    .layout-mask {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 998;
      width: 100%;
      height: 100%;
      background-color: var(--maskbg);
    }

    .topbar-breadcrumb {
      display: none;
    }

    .content-breadcrumb {
      display: block;
    }
  }
}

@media screen and (min-width: 1960px) {
  .layout-content,
  .landing-wrapper {
    width: 1504px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  //.layout-topbar {
  //  width: 1504px;
  //  margin-left: auto !important;
  //  margin-right: auto !important;
  //}
}
