/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import 'assets/layout/styles/layout/layout.scss';

@import 'assets/font';

/* PrimeNG */
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';

/* Quill Text Editor for p-editor */
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.snow.css';

/* Demos */
//@import "assets/demo/styles/flags/flags.css";
//@import "assets/demo/styles/badges.scss";
//@import "assets/demo/styles/code.scss";

@import 'assets/custom';
@import 'assets/custom-primeng';
