@media screen and (min-width: 992px) {
  .layout-container.layout-horizontal .layout-sidebar {
    height: 56px;
    position: sticky;

    > div {
      display: flex;
      // width: 1504px;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}

.layout-menu .menu-separator {
  margin: 0 10px;
  border-top: solid 1px var(--primary-color-text);
}

.topbar-account-info {
  .topbar-userinfo {
    text-align: right;
    margin-right: 10px;
  }
}

//.my-canvas {
//  .konvajs-content {
//    outline: 2px dashed var(--primary-color);
//  }
//}

.app-logo {
  color: var(--primary-color-text);
  font-size: 1.4rem;
  font-weight: 600;
}

.list-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.p-text-danger a span {
  color: #ef4444; // $dangerButtonBg;
}

.p-button {
  padding: 0.5rem 1rem;
  height: 35px;
  font-size: 14px;

  i {
    font-size: 14px;
  }
}
.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}
.p-inputtext {
  height: 35px;
  font-size: 14px;
  padding: 8px;

  .p-inputgroup-addon {
    height: 35px;
  }
}
.p-dropdown {
  height: 35px;
  width: 100%;
  max-width: 100%;
}
.p-dropdown-item {
  font-size: 14px;
}
.p-multiselect {
  height: 35px;
  font-size: 14px;
  padding: 5px;
  width: 100%;
  max-width: 100%;

  .p-multiselect-label {
    padding: 0;
    font-size: 14px;
  }
  .p-multiselect-token {
    height: 20px;
  }
}
.p-treeselect-label-container {
  height: 35px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.white-space-nowrap {
  white-space: nowrap;
}
.menu-items-list {
  .p-orderlist-list {
    padding: 0;
    border: none;
  }
  .p-orderlist-controls {
    display: none;
  }
  .p-orderlist-item {
    padding: 0;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0;
    position: initial;
  }

  .p-panel .p-panel-content {
    padding: 0;
  }

  .p-panel .p-panel-header {
    border-radius: 0;
    background: transparent;
  }

  .p-orderlist .p-orderlist-list {
    border: none;
    min-height: 0;
    padding: 0;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0 !important;
  }
}
.table-row-disabled {
  opacity: 0.2;
  pointer-events: none;
  cursor: not-allowed;
  position: relative;
  td:last-child::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 5px solid var(--primary-color);
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: rotation 1s linear infinite;
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(-50%, -50%);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.p-orderlist-item {
  margin-bottom: 10px;
  border-radius: 24px;
  border: 1px solid #d7dded;
}

.p-panel-header {
  border: none;
  padding: 0.9rem;
  background: transparent;
}

.p-panel-content {
  border: none;
}
.upload-background-grid {
  background:
    linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.7)),
    url('assets/images/bg-pattern.png') center center repeat;
  border: 1px solid #d7dded;
  border-radius: 24px;
  background-size: cover;

  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
  }

  .upload-icon {
    width: 27px;
    height: 24px;
  }
}
.p-splitbutton-defaultbutton {
  border-right: none !important;
}
.split-no-border {
  .p-splitbutton {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);

    .p-button,
    .p-button-label {
      border: none;
    }
  }
}

.small-split-button {
  .p-splitbutton {
    height: 28px;
    font-size: 12px;

    .p-button,
    .p-button-label {
      height: auto;
      padding: 5px;
      font-size: 14px;
    }
    .p-button-icon-only {
      width: 28px;
    }
  }
  svg {
    height: 14px;
    width: 14px;
  }
}

.p-overlaypanel-content {
  padding: 0px;
}

.catalogProductsList .product-inactive-row {
  background: rgba(232, 30, 30, 0.26);
}
.redirect-link {
  padding: 5px;
  color: var(--primary-color);

  &:hover {
    color: #ffffff;
  }
}
