/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('../../fonts/poppins-v20-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../fonts/poppins-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('../../fonts/poppins-v20-latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../fonts/poppins-v20-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('../../fonts/poppins-v20-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../fonts/poppins-v20-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('../../fonts/poppins-v20-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../fonts/poppins-v20-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: auto;
  font-style: auto;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: auto;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: auto;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: auto;
}
